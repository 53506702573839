import * as R from "ramda";
import { sortArticlesByKoodiarvo } from "../services/lomakkeet/utils";

type Koulutus = {
  koodiarvo: string;
  koodisto: {
    koodistoUri: string;
  };
  metadata: {
    [key: string]: {
      nimi: string;
    };
  };
};

// Vain tarvittavat elementit maaritelty, voi sisaltaa muitakin proppeja
type SimpleChangeObject = {
  anchor: string;
  properties: {
    isChecked: boolean;
  };
};

type Maarays = {
  koodiarvo: string;
  koodisto: string;
  uuid: string;
};

/**
 * Luo objektin, joka sisältää lupaa koskevia perustietoja.
 * @param koulutukset Taulukollinen tietoja (koodistopalvelusta).
 * @param locale fi | sv
 * @param maaraykset Taulukollinen määräyksiä.
 * @param koodisto Merkkijono, josta käy ilmi koodisto.
 * @param sectionId
 * @param changeObjects changeObjects kertoo onko kentta valittu vai ei, tiedot voi tulla tietokannasta tai selaimen muistista
 * @param defaultSelectionEnabled true asetuksella valitaan viimeinen kentta, false ei tee valintaa. Talla ei ole mitaan vaikutusta jos changeObjects tai maarays sisaltaa valinnan (isChecked tiedon).
 * @returns Objekti, jota voi hyödyntää lomakemerkkauksen luomisessa.
 */
export function getDataForKoulutusList(
  koulutukset: Array<Koulutus>,
  locale: string,
  maaraykset: Array<Maarays>,
  koodisto: string | null = null,
  sectionId: string | null = null,
  changeObjects: Array<SimpleChangeObject> | null = null,
  defaultSelectionEnabled: boolean | null = true
): Record<string, unknown> {
  const relevantitMaaraykset = koodisto
    ? (R.reject(
        R.isNil,
        R.map(maarays => {
          return maarays.koodisto === koodisto ? maarays : null;
        }, maaraykset)
      ) as Array<Maarays>)
    : [];

  const luvassaOlevatKoodiarvot = R.map(
    R.prop("koodiarvo"),
    relevantitMaaraykset
  );

  const sortedKoulutukset: Array<Koulutus> =
    sortArticlesByKoodiarvo(koulutukset);

  return {
    items: sortedKoulutukset.map((koulutus, index) => {
      const maarays = R.find(
        R.propEq("koodiarvo", koulutus.koodiarvo),
        relevantitMaaraykset
      );
      const isInLupa = !!(
        koodisto && R.includes(koulutus.koodiarvo, luvassaOlevatKoodiarvot)
      );
      let selected = undefined;
      if (changeObjects) {
        const isCurrentElement = (e: SimpleChangeObject) =>
          e.anchor.startsWith(sectionId + "." + koulutus.koodiarvo); // anchor = koulutukset_valmentavatKoulutukset.999903.A -> startsWith koulutukset_valmentavatKoulutukset.999903 -> true
        const filteredChangeObjects = R.filter(isCurrentElement, changeObjects);
        const currentChangeObj = R.last(filteredChangeObjects);
        if (currentChangeObj) {
          selected = R.path(["properties", "isChecked"], currentChangeObj);
        }
      }

      return {
        code: koulutus.koodiarvo,
        isInLupa,
        isReasoningRequired: index !== koulutukset.length - 1, // Perustelut tarvitaan kaikille muille elementeille paitsi viimeiselle, bugiherkka toteutus.
        // true jos elementti on luvalla. True jos tulee tietokannasta ja true/false jos tulee selaimen muistista tai jos oletusvalinta kytketty paalle ja kyseessa on viimeinen elementti.
        // Luvalla oleminen true, koska ammattillinen puoli ei tallenna poistettuja objekteja tietokantaan eli vain jos lomakkeelta on valittu elementti, niin tietokantaan on tehty merkinta ja tasta voi paatella sen olevan valittu
        // selected lipun tarkoituksena on tarkistaa valinta changeObjects tiedostosta, joka voi tulla tietokannasta tai selaimen muistista. Ensimmaista kertaa lomaketta ladatessa tama on true jos valinta on tietokannasta, muilla kerroilla tieto voi tulla myos selaimen valimuistista.
        // defaultSelectionEnabled jos true niin viimeinen elementti valitaan automaattisesti. tama on oletusasetus, mutta sen voi kytkea erikseen pois sellaisilta kentilta joita ei saa erikseen olla valittuna kuten valmentavat koulutukset.
        shouldBeChecked:
          isInLupa || // jos luvalla niin true
          selected || // Tieto kannassa tai selaimen muistista
          (defaultSelectionEnabled && // halutaanko viimeinen elementti valita? talla ei ole merkitysta jos tieto saadaan luvalta, kannasta tai selaimen muistista
            R.isEmpty(luvassaOlevatKoodiarvot) &&
            index === koulutukset.length - 1 && // Vain jos kyseessa viimeinen elementti, joka ei ole luvalla.
            koodisto === koulutus.koodisto.koodistoUri),
        koodisto: koulutus.koodisto,
        sectionId,
        maaraysUuid: maarays ? maarays.uuid : null,
        maarays,
        metadata: koulutus.metadata,
        title: koulutus.metadata[locale].nimi
      };
    })
  };
}
